
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: Number,
    name: String,
    items: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});


import { PopoutsModule } from "@/store/modules/popouts";
import CustomButton from "@/components/CustomButton.vue";
import Selector from "../../Selector.vue";
import ElectronSources from "./ElectronSources.vue";

import { defineComponent } from "vue";
import { voiceChannelModule } from "@/store/modules/voiceChannels";

export default defineComponent({
  name: "ScreensharePopout",
  components: { CustomButton, Selector, ElectronSources },
  props: {
    identity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      qualityItems: ["480p", "720p", "1080p", "Source"],
      framerateItems: ["30fps", "60fps", "Source"],
      selectedQuality: 3,
      selectedFramerate: 2,
      selectedSourceId: null,
    };
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout(this.identity);
    },
    async selectWindow() {
      voiceChannelModule.removeVideoStream();
      const constraints = await this.constructConstraints();
      const mediaDevices = navigator.mediaDevices as any;

      let stream: any = null;
      // handle electron
      if (this.selectedSourceId) {
        stream = await mediaDevices.getUserMedia({
          audio: {
            mandatory: {
              chromeMediaSource: "desktop",
            },
          },
          video: {
            mandatory: {
              chromeMediaSource: "desktop",
              chromeMediaSourceId: this.selectedSourceId,
              maxFrameRate: constraints.video.frameRate,
              minWidth: constraints.video.width,
              maxWidth: constraints.video.width,
              minHeight: constraints.video.height,
              maxHeight: constraints.video.height,
            },
          },
        });
        await stream.getAudioTracks()[0].applyConstraints(constraints.audio);
      } else {
        stream = await mediaDevices.getDisplayMedia(constraints).catch(() => {
          console.log("Screenshare cancelled.");
        });
      }

      if (this.$isElectron && !this.selectedSourceId) return;

      this.close();
      if (!stream) return;
      voiceChannelModule.addStream({ stream, type: "video" });
    },
    getFPS(): Promise<number> {
      return new Promise((resolve) =>
        requestAnimationFrame((t1) =>
          requestAnimationFrame((t2) => resolve(1000 / (t2 - t1)))
        )
      );
    },
    async getRoundedFps() {
      return Math.round((await this.getFPS()) / 10) * 10;
    },
    async constructConstraints() {
      const constraints = {
        video: {
          height: 0,
          width: 0,
          frameRate: 0,
          resizeMode: null as string | null,
        },
        audio: {
          autoGainControl: false,
          echoCancellation: false,
          googAutoGainControl: false,
          noiseSupperession: false,
        },
      };
      // quality
      switch (this.selectedQuality) {
        case 0:
          constraints.video = { width: 848, height: 480 } as any;
          break;
        case 1:
          constraints.video = { width: 1280, height: 720 } as any;
          break;
        case 2:
          constraints.video = { width: 1920, height: 1080 } as any;
          break;
        case 3:
          constraints.video = {
            width: window.screen.width,
            height: window.screen.height,
          } as any;
          break;
        default:
          break;
      }
      // framerate
      switch (this.selectedFramerate) {
        case 0:
          constraints.video.frameRate = 30;
          break;
        case 1:
          constraints.video.frameRate = 60;
          break;
        case 2:
          constraints.video.frameRate = await this.getRoundedFps();
          break;
        default:
          break;
      }
      constraints.video.resizeMode = "none";
      return constraints;
    },
  },
});


import { CaptureSource } from "@/interfaces/ElectronBridge";
import electronBridge from "@/utils/electronBridge";
import { defineComponent } from "@vue/runtime-core";
import ElectronSourceTemplate from "./ElectronSourceTemplate.vue";
export default defineComponent({
  components: { ElectronSourceTemplate },
  data() {
    return {
      selectedSourceId: null as null | string,
      electronSources: [] as CaptureSource[],
    };
  },
  mounted() {
    this.loadElectronCaptures();
  },
  methods: {
    onClick(source: CaptureSource) {
      this.$emit("selectedSourceId", source.id);
      this.selectedSourceId = source.id;
    },
    async loadElectronCaptures() {
      const getCaptureSources = electronBridge?.getCaptureSources;
      if (!getCaptureSources) return;
      this.electronSources = await getCaptureSources();
    },
  },
});


import { defineComponent, PropType } from "vue";
import { CaptureSource } from "@/interfaces/ElectronBridge";

export default defineComponent({
  props: {
    selected: Boolean,
    source: {
      type: Object as PropType<CaptureSource>,
      required: true,
    },
  },
});
